import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgMujerRostro, ImgMujerRostro, ContImgNinioRostro, ImgNinioRostro} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import useSound from 'use-sound';
import {gsap} from "gsap";
import imgMujerRostro from '../../../images/obras/obra-7/mujer-rostro.png';
import imgNinioRostro from '../../../images/obras/obra-7/ninio-rostro.png';
import soundMujer from '../../../images/obras/obra-7/naturaleza.mp3';
import imgLeft from '../../../images/obras/obra-6/obra.jpeg';
import imgRight from '../../../images/obras/obra-8/obra.jpeg';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 8,
  bottom: null,
  left: 6,
};

const Page07Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMujerRostro, setInitAnimatedMujerRostro] = useState(false);
  const [initAnimatedNinioRostro, setInitAnimatedNinioRostro] = useState(false);
  const [initSoundMujer, setInitSoundMujer] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-7/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchMujerRostro(0);
    fnReLaunchNinioRostro(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchMujerRostro = (noReplay = 1) => {
    setInitAnimatedMujerRostro(false);
    // setInitSoundMujer(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujerRostro(true);
      }, 1000);
    }
  };
  const fnReLaunchNinioRostro = (noReplay = 1) => {
    setInitAnimatedNinioRostro(false);
    // setInitSoundTriceratops(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedNinioRostro(true);
      }, 1000);
    }
  };
  const fnAnimateMujerRostro = () => {
    return (
      <ContImgMujerRostro className={'img-animated'} onClick={() => fnReLaunchMujerRostro()}>
        {
          initAnimatedMujerRostro &&
          <ImgMujerRostro
            id={'animate-mujer-rostro'}
            className={'animate-mujer-rostro'}
            src={imgMujerRostro}
            alt="Hombre"
          />
        }
      </ContImgMujerRostro>
    )
  }
  const fnAnimateNinioRostro = () => {
    return (
      <ContImgNinioRostro className={'img-animated'} onClick={() => fnReLaunchNinioRostro()}>
        {
          initAnimatedNinioRostro &&
          <ImgNinioRostro
            id={'animate-ninio-rostro'}
            className={'animate-ninio-rostro'}
            src={imgNinioRostro}
            alt="Hombre"
          />
        }
      </ContImgNinioRostro>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div> En la época oscura del año.</div>
            <div>Entre derretirse y congelarse.</div>
            <div>La savia del alma tiembla.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playMujer, { stopMujer }] = useSound(
    soundMujer,
    { volume: 0.2 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMujerRostro(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedNinioRostro(true);
    }, 6000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 9000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosMujer = {};
    let arPosNinio = {};

    if(winWidth < 767){
      arPosMujer = {x: 200}
      arPosNinio = {y: 100}
    } else if(winWidth < 1023){
      arPosMujer = {x: 160}
      arPosNinio = {y: 290}
    } else if(winWidth < 1215){
      arPosMujer = {x: 100}
      arPosNinio = {y: 450}
    } else if(winWidth < 1407){
      arPosMujer = {x: 220}
      arPosNinio = {y: 200}
    } else if(winWidth < 1919){
      arPosMujer = {x: 200}
      arPosNinio = {y: 250}
    } else {
      arPosMujer = {x: 200}
      arPosNinio = {y: 300}
    }

    if(initAnimatedMujerRostro){
      /*gsap.fromTo(".animate-mujer-rostro", 2, {
        x: 0,
      }, {
        x: 250,
      }, "first");*/
      gsap.to('.animate-mujer-rostro', {
        x: arPosMujer.x,
        delay: 0,
        duration:2,
        ease:"linear",
      });

      if(!initSoundMujer){
        playMujer();
        setInitSoundMujer(true);
      }
    }

    if(initAnimatedNinioRostro){
      /*gsap.fromTo(".animate-ninio-rostro", 2, {
        y: 0,
        scaleY: 0,
        skewY: 0,
      }, {
        y: 200,
        scaleX: 3,
        scaleY: 3,
        skewX: 3,
        skewY: 3,
      }, "second");*/

      gsap.to('.animate-ninio-rostro', {
        y: arPosNinio.y,
        scaleX: 3,
        scaleY: 3,
        skewX: 3,
        skewY: 3,
        delay: 0,
        duration:5,
        ease:"linear",
      });

      /*gsap.fromTo(".animate-ninio-rostro", 2, {
        x: 0,
        y: 200
      }, {
        x: -200,
        y: 310
      }, "thirt");*/

      /*if(!initsoundTriceratops){
        setTimeout(function(){
          playTriceratops();
          setInitsoundTriceratops(true);
        }, 2000);
      }*/
    }

  }, [initAnimatedMujerRostro, initAnimatedNinioRostro]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateMujerRostro()}
            {fnAnimateNinioRostro()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page07Comp;
