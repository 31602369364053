import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 290px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -255px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 572px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -100px;
      margin-left: 250px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: 345px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -156px;
      margin-left: 170px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: 270px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: 310px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgMujerRostro = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    height: 59%;
    margin-top: -130px;
    margin-left: -350px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: auto;
    height: 91%;
    margin-top: -10px;
    margin-left: -450px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: auto;
    height: 93%;
    margin-top: -10px;
    margin-left: -450px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: auto;
    height: 94%;
    margin-top: -10px;
    margin-left: -450px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: auto;
    height: 96%;
    margin-top: -10px;
    margin-left: -450px;
  }
  @media(min-width: 1920px){
    width: auto;
    height: 96%;
    margin-top: -10px;
    margin-left: -450px;
  }
`
export const ImgMujerRostro = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgNinioRostro = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    height: auto;
    margin-top: -200px;
    margin-left: 20px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80px;
    height: auto;
    margin-top: -250px;
    margin-left: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 90px;
    height: auto;
    margin-top: -400px;
    margin-left: 70px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 70px;
    height: auto;
    margin-top: -195px;
    margin-left: 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 90px;
    height: auto;
    margin-top: -260px;
    margin-left: 40px;
  }
  @media(min-width: 1920px){
    width: 90px;
    height: auto;
    margin-top: -320px;
    margin-left: 60px;
  }
`
export const ImgNinioRostro = styled.img`
  width: 100%;
  height: 100%;
`
